<template>

  <div class="fI login-page ">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText">
        <div>~ <translate>PASSWORD RESET</translate> ~</div>
      </div>
    </div>

    <!--LoginWidgets /-->

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div style="width: 100%; overflow: hidden;">

        <FormulateForm
          v-model='values'
          :errors='errors'
          @submit="resetPassword()"
          #default="{ hasErrors }"
          v-if="!hasUpdatedPass"
        >
          <div id="forgotPasswordForm" >
            <div v-if='errors.root' class='landingPageInput item-errors alert alert-danger'>
              <translate>Password reset error:</translate> { errors.root }
            </div>
            <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper form-group">
              <div class="formText" style="padding: 0px 0px 20px 0;width: 35%;margin: auto;" v-translate>
                Please choose a <b>new password</b> now.
              </div>
              <FormulateInput
                class="landingPageInput"
                type="password"
                name="password"
                :placeholder="$gettext('password')"
                validation='required'
                style="margin-bottom: 5px;"
              />
              <FormulateInput
                class="landingPageInput"
                type="password"
                name="password_confirm"
                validation='required|confirm'
                :placeholder="$gettext('repeat password')"
              />
            </div>
            <div class="logo_spacer_bottom"></div>
            <div class="form-group">
              <button v-if='hasErrors' disabled class="landingPageButton btn btn-primary trans" style="opacity: 0.3; cursor: not-allowed;"><i class="fas fa-recycle fas-left"></i><translate>RESET PASSWORD</translate></button>
              <button v-else class="landingPageButton btn btn-success" ><i class="fas fa-recycle fas-left"></i><translate>RESET PASSWORD</translate></button>
              <br/>
              <router-link class='loginSubBtn w-50 mt-2 btn btn-outline-secondary btn-small' :to="{ name: 'login' }">
                <translate>Return to Login</translate>
              </router-link>
            </div>
          </div>
        </FormulateForm>

        <div v-else style="width: 100%;" class="fI">
          <div class="logo_spacer_top"></div>
          <div class="loginFormWrapper fI-slow" style="padding: 28px 0 29px 0;" v-translate>
            Password reset <b>successful</b>!
          </div>
          <div class="logo_spacer_bottom"></div>
          <div class="form-group">
            <router-link class='loginSubBtn w-50 mt-2 btn btn-outline-secondary btn-small fI-slow' :to="{ name: 'login' }">
              <translate>Return to Login</translate>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { AUTH__RESET_PASSWORD } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'

import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

// import LoginWidgets from '@/components/widgets/LoginWidgets'

export default {
  name: 'ResetPassword',
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  data () {
    return {
      values: {
        password: '',
        password_confirm: ''
      },
      token: this.$route.query.token,
      hasUpdatedPass: false,
      errors: {}
    }
  },
  computed: {
    hasErrors (state) {
      return (Object.keys(this.errors).length > 0)
    }
  },
  methods: {
    resetPassword () {
      if (this.errors) { this.errors = null }
      this.$store
        .dispatch(AUTH__RESET_PASSWORD, {
          password: this.values.password,
          password_verify: this.values.password_confirm,
          token: this.token
        })
        .then(() => {
          this.hasUpdatedPass = true
        }).catch((err) => {
          this.handleErrors(err)
        })
    },
    handleErrors (err) {
      if (parseInt(err.response.status) === 400) {
        this.errors = { root: err.response.data.detail }
      } else if (parseInt(err.response.status) === 422) {
        const errorsObject = { password: [], password_verify: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })
        this.errors = { ...errorsObject, password_confirm: errorsObject.password_verify }
      }
    }
  }
}
</script>

<style>
  @import '../assets/style/sessions.scss';
</style>
